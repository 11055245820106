.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;

  margin-block: 24px;
  margin-inline: 8px;
}

.app-title {
  margin: 0;
  color: var(--color-primary);
  word-break: normal;
  font-size: 3.2rem;
}

.app-subtitle {
  margin: 0;
}
