:root {
  --color-bg-1: #ead7d1;
  --color-bg-2: #dbcdc6;
  --color-primary: #38405f;
  --color-primary-lighter: #444e74;
  --color-text-on-primary: #f6f5f4;
  --color-text: #111724;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  background-color: var(--color-bg-2);
  color: var(--color-text);
  box-sizing: border-box;
  font-size: 1.6rem;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

a,
a:visited {
  color: var(--color-primary);
}
