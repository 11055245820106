.button {
  background-color: var(--color-primary);
  color: var(--color-text-on-primary);
  font-size: 1.6rem;

  border-radius: 8px;
  border: none;

  padding-block: 6px;
  padding-inline: 12px;

  cursor: pointer;

  transition: background-color 50ms linear;

  &:hover {
    background-color: var(--color-primary-lighter);
  }
}
